import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import slugify from "slugify";

function Hero() {
  const data = useStaticQuery(query);
  const allcategories = data.allMdx.distinct;
  /* print only 5 items from array object  */
  const limitedCategories = allcategories.slice(0, 9);
  return (
    <section className="home">
      <div className="intro">
        <div className="intro-text">
          <h1>Explore</h1>
          <h3>Learn and build </h3>
          <p>this blog is about web development</p>
        </div>
        <div className="cta">
          <Link to="/tags">
            <button className="cta-action">Start Learning & building </button>
          </Link>
        </div>
      </div>
      <div className="tags">
        <div className="tags-cta">
          {limitedCategories.map((category, index) => {
            const slug = slugify(category, { lower: true });
            return (
              <Link to={`/${slug}`} key={index}>
                <button className={`cta-tag ${category}`}>{category}</button>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
  {
    allMdx {
      distinct(field: frontmatter___category)
    }
  }
`;

export default Hero;
