import React from "react";
import About from "./About";
/* import BannerCategories from "./BannerCategories"; */
import Recent from "./Recent";

function index() {
  return (
    <>
      <div className="banner">
        <About />
        <Recent />
        {/* <BannerCategories /> */}
      </div>
    </>
  );
}

export default index;
