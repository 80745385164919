import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

const Recent = () => {
  const data = useStaticQuery(query);
  const posts = data.allMdx.nodes;
  // console.log(posts)
  return (
    <div>
      <div className="underline"></div>
      {posts.map((post) => {
        const { title, slug, Update } = post.frontmatter;
        // console.log(title)
        return (
          <Link to={`/posts/${slug}`} key={post.id} className="Recentpost">
            <div>
              <h5>{title}</h5>
              <p>{Update}</p>
            </div>
          </Link>
        );
      })}
      <div className="underline"></div>
    </div>
  );
};

export const query = graphql`
  {
    allMdx(limit: 4, sort: { fields: frontmatter___Update, order: DESC }) {
      nodes {
        id
        frontmatter {
          title
          slug
          Update(formatString: "MMM,Do,YYYY")
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default Recent;
