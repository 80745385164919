import React from "react";
import SocialLinks from "../socialLinks";
import { StaticImage } from "gatsby-plugin-image";

const About = () => {
  return (
    <div className="aboutContainer">
      <StaticImage
        src="../../assets/user.png"
        layout="fixed"
        width={100}
        height={100}
        alt="author"
        className="img"
      />
      <h4 className="aboutTitle">Sumit Harijan</h4>
      <p className="aboutSubtitle">Web developer</p>
      <p className="aboutme">
        My name is sumit , I am a content & web developer from india 🇮🇳 creator
        of coding.batch (developer community)
      </p>
      <SocialLinks styleClass="banner-icons" />
    </div>
  );
};

export default About;
